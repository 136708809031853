/** @format */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import LoginModal from './LoginModal';
/**css */
import logo from '../../images/logo.png';
import '../scss/nav.scss';

function Navbar(props) {
	// const [isModalOpen, setIsModalOpen] = useState(false);

	// const openModal = () => {
	// 	setIsModalOpen(true);
	// };

	// const closeModal = () => {
	// 	setIsModalOpen(false);
	// };

	return (
		<>
			<header>
				<div className="nav">
					<Link
						className="logo-link"
						to="/"
					>
						<div className="ux-brand">
							<img
								src={logo}
								className="logo"
								alt="logo"
							/>
							<p>{props.text.name}</p>
						</div>
					</Link>

					<div className="nav-text">
						<h1>{props.text.page}</h1>
						<p>{props.text.content}</p>
					</div>

					{/* <button
						onClick={openModal}
						className="secondary-btn"
					>
						Sign in
					</button> */}
				</div>
				{/* <LoginModal
					open={isModalOpen}
					close={closeModal}
				/> */}
			</header>
		</>
	);
}
export default Navbar;
