/** @format */

import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Select from 'react-dropdown-select';
import '../scss/feedback.scss';

export default function Feedback() {
	const navigate = useNavigate();
	const [modalOpen, setModalOpen] = useState(false);
	const [messageSent, setMessageSent] = useState(false);

	/**Open Modal */
	const handleShowModal = (e) => {
		e.preventDefault();
		setModalOpen(true);
		const keyboardfocusableElements = [...document.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')].filter(
			(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
		);
		keyboardfocusableElements.forEach((item) => {
			item.setAttribute('data-previous-tabindex', item.getAttribute('tabindex'));
			item.setAttribute('tabindex', '-1');
		});
	};

	/**closeModal */
	const handleCloseModal = () => {
		const keyboardfocusableElements = [...document.querySelectorAll('[data-previous-tabindex]')];

		keyboardfocusableElements.forEach((item) => {
			var oldIndex = item.getAttribute('data-previous-tabindex');
			if (oldIndex === 'null') {
				item.removeAttribute('tabindex');
			} else {
				item.setAttribute('tabindex', oldIndex);
			}

			item.removeAttribute('data-previous-tabindex');
		});

		setModalOpen(false);
		setMessageSent(false);
	};

	const Form = () => {
		const [answer, setAnswer] = useState('');
		const [name, setName] = useState('');
		const [email, setEmail] = useState('');
		const [message, setMessage] = useState('');
		const [feedbackType, setFeedbackType] = useState('');
		const [captchaToken, setCaptchaToken] = useState(null);


		/**reCAPTCHA token and key */
        useEffect(() => {
            const loadRecaptcha = () => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute('6Lfwu_UpAAAAAOsAeAEEPn0Y2x4-lsunlV0AXyfw', { action: 'submit' }).then((token) => {
                        setCaptchaToken(token);
                    });
                });
            };
            loadRecaptcha();
        }, []);
		const handleAnswerChange = (event) => {
			setAnswer(event.target.value);
		};

		/***Send input data */
		const handleSubmit = async (e) => {
			e.preventDefault();
	
			if (!captchaToken) {
                console.log("CAPTCHA validation failed");
                return;
            }
	
			let data = {
				to: 'gpduxfeedback@grp.pearson.com',
				type: feedbackType,
				subject: 'Message from UX Career website',
				name: name,
				email: email,
				message: message,
				'recaptcha-token': captchaToken,
			};
	
			fetch('https://pearsonct.design/server/api/email', {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			})
			.then((d) => {
				setMessageSent(true);
			})
			.catch((err) => {
				console.log(err);
			});
	
			console.log(data);
			setMessageSent(true);
		};
	
		const feedbackOptions = [
			{value: 'Comment', label: 'Comment'},
			{value: 'Suggestion', label: 'Suggestion'},
			{value: 'Feedback', label: 'Feedback'},
		];

		return (
			<form id="feedback-form" onSubmit={handleSubmit}>
				<div className="feedback-header">
					<h2>Send Feedback</h2>
					<button type="button" className="close-btn" onClick={handleCloseModal}>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M12.0001 10.5858L18.7176 3.86833C19.1081 3.47781 19.7413 3.47781 20.1318 3.86833C20.5223 4.25886 20.5223 4.89202 20.1318 5.28255L13.4143 12.0001L20.1317 18.7175C20.5223 19.108 20.5223 19.7412 20.1317 20.1317C19.7412 20.5223 19.108 20.5223 18.7175 20.1317L12.0001 13.4143L5.28246 20.1317C4.89194 20.5222 4.25877 20.5222 3.86825 20.1317C3.47772 19.7412 3.47772 19.108 3.86825 18.7175L10.5858 12.0001L3.86842 5.28263C3.47789 4.89211 3.47789 4.25894 3.86842 3.86842C4.25894 3.47789 4.89211 3.47789 5.28263 3.86842L12.0001 10.5858Z"
							/>
						</svg>
					</button>
				</div>

				<section className="feedback-body">
					<h1>Give us your feedback</h1>

					<div className="question">
						<label htmlFor="feedback-type" className="feedback-type">
							What type of feedback would you like to provide? *
						</label>
						<Select
							required
							id="feedback-type"
							options={feedbackOptions}
							onChange={(values) => setFeedbackType(values[0].value)}
							values={[feedbackType]}
							className="feedback-select"
						/>
					</div>

					<div className="question">
						<label htmlFor="message">Please tell us about your experience.</label>
						<textarea
							required
							name="message"
							id="message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>

					<div className="question">
						<label htmlFor="radio">Would you like us to follow up with you? *</label>
						<div className="radio-group">
							<input
								type="radio"
								name="radio"
								id="no"
								value="no"
								defaultChecked
								onChange={handleAnswerChange}
							/>
							<label htmlFor="no">No</label>

							<input
								type="radio"
								name="radio"
								id="yes"
								value="yes"
								checked={answer === 'yes'}
								onChange={handleAnswerChange}
							/>
							<label htmlFor="yes">Yes</label>
						</div>
					</div>

					{answer === 'yes' && (
						<div className="question leave-name">
							<label htmlFor="name">Name</label>
							<input
								type="text"
								id="name"
								name="name"
								value={name}
								autoComplete="off"
								onChange={(e) => setName(e.target.value)}
							/>
							<label
								htmlFor="email"
								className="email">
								E-mail
							</label>
							<input
								type="text"
								id="email"
								name="email"
								value={email}
								autoComplete="off"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
					)}
				</section>

				<ReCAPTCHA
					sitekey="6Lfwu_UpAAAAAOsAeAEEPn0Y2x4-lsunlV0AXyfw"
					size="invisible"
					onChange={(token) => setCaptchaToken(token)}
				/>
				<button type="submit" className="btn">Send</button>
			</form>
		);
	};

	const Thanks = () => {
		return (
			<div className="thanks">
				<div className="feedback-header">
					<h2>Feedback sent</h2>
					<button
						type="button"
						className="close-btn"
						onClick={handleCloseModal}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M12.0001 10.5858L18.7176 3.86833C19.1081 3.47781 19.7413 3.47781 20.1318 3.86833C20.5223 4.25886 20.5223 4.89202 20.1318 5.28255L13.4143 12.0001L20.1317 18.7175C20.5223 19.108 20.5223 19.7412 20.1317 20.1317C19.7412 20.5223 19.108 20.5223 18.7175 20.1317L12.0001 13.4143L5.28246 20.1317C4.89194 20.5222 4.25877 20.5222 3.86825 20.1317C3.47772 19.7412 3.47772 19.108 3.86825 18.7175L10.5858 12.0001L3.86842 5.28263C3.47789 4.89211 3.47789 4.25894 3.86842 3.86842C4.25894 3.47789 4.89211 3.47789 5.28263 3.86842L12.0001 10.5858Z"
							/>
						</svg>
					</button>
				</div>

				<div className="feedback-body">
					<h1>Your feedback has been successfully submitted. Thank you!</h1>
				</div>
				<button
					type="button"
					className="btn"
					onClick={() => {
						handleCloseModal();
						navigate('/');
					}}>
					Return to Home
				</button>
			</div>
		);
	};

	return (
		<>
			<div className="feedback-btn-container">
				<a
					href="/"
					role="button"
					className="feedback-btn"
					onClick={handleShowModal}>
					<p>Feedback</p>
				</a>
			</div>

			{modalOpen === true ? (
				<div className="modal-grid">
					<div className={`modal-container`}>{messageSent === false ? <Form /> : <Thanks />}</div>
				</div>
			) : null}
		</>
	);
}
