/** @format */

import { useState } from 'react';
/****css */
import '../scss/teamResources.scss';
/**json */
import TeamResourceData from '../../data/teamResources.json';
/***component */
import Modal from './LoginModal';

function TeamResources() {
	const [modalOpen, setModalOpen] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => {
		setModalOpen(false);
	};

	return (
		<div className="box">
			{TeamResourceData.map((resource, i) => {
				return (
					<div
						id="cardBox"
						key={i}
					>
						<a
							href={resource.link}
							target="_blank"
							rel="noreferrer"
						>
							<div className="mask">
								<img
									src={resource.img}
									className="image"
								/>

								<div className="body ">
									<h2 className="title">{resource.title}</h2>
									<div className="sub-title-box">
										<p className="content">{resource.content}</p>
										<button
											href={resource.link}
											target="_blank"
											className="secondary-btn"
										>
											Explore
										</button>
									</div>
								</div>
							</div>
						</a>
					</div>
				);
			})}
		</div>
	);
}

export default TeamResources;
